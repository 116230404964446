import axios from "axios";
import { API_BASE } from "../spectory-config";

export const communicator = async (endpoint, payload, type = 'post') => {

    
    const config = {
        method: type,
        url: `${API_BASE}${endpoint}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };

    console.log("REQUEST TO SERVER", config)

    const result = await axios(config);

    return new Promise((resolve, reject) => {

        console.log("RESPONSE FROM SERVER", result);

        if (result.data.c !== 200) {
            reject(result.data)
        } else {
            resolve(result.data);
        }

    })

}
import React, { useEffect, useState } from "react"
import * as styles from "./styles.module.scss"

import XIcon from '../../../images/Icons/cross.svg';

const Modal = ({ state, close, children, style, contentStyle }) => {

    const [open, setOpen] = useState(false);
    const [enabled, setEnabled] = useState(state);

    useEffect(() => {

        if (state) { // IF SHOULD OPEN

            setEnabled(true);

            setTimeout(() => {
                setOpen(true)
            }, 50)

        } else {

            setOpen(false)

            setTimeout(() => {
                setEnabled(false);
            }, 500)
        }

    }, [state]);

    if (!enabled) return null;

    return (
        <div
            className={`${styles.container} ${open ? styles.show : undefined}`}
            style={style}
        >
            <div
                className={styles.closeButton}
                onClick={close}
            ><XIcon /></div>
            <div className={styles.content} style={contentStyle}>
                {children}
            </div>
        </div>
    );

}

export default Modal

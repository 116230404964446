import { Link } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import QRCode from 'react-qr-code';

import Button from "../../../components/elements/Button"
import Card from "../../../components/elements/Card"
import TextInput from "../../../components/elements/Inputs/TextInput"
import { getUserData, getUserToken } from "../../../services/auth"
import AccountNavigation from "../Navigation"
import Modal from '../../../components/elements/Modal';
import LoadingSpinner from '../../../components/elements/LoadingSpinner';
import * as modalStyles from '../../../components/elements/Modal/styles.module.scss';
import { communicator } from "../../../services/communicator";
import { setNotification } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { floatToString } from "../../../functions/money";

const Overview = (props) => {

    const { register, handleSubmit, reset } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const [loading, setLoading] = useState(true);
    const [customerNumber, setCustomerNumber] = useState("1234 5678 9000");
    const [qrCodeWidth, setQrCodeWidth] = useState(0);
    const [qrcode, setQrcode] = useState('');
    const [userData, setUserData] = useState({});
    const [nimmervollUserData, setNimmervollUserData] = useState({});

    const [nimmervollCardUsage, setNimmervollCardUsage] = useState({});

    const ref = useRef();

    const dispatch = useDispatch();

    useEffect(() => {

        setUserData(getUserData());

        // GET USER DATA FROM NIMMERVOLL API
        communicator('/nv/customer/card/data', { token: getUserToken() }).then(res => {
            setNimmervollUserData(res.data);
            setLoading(false);
        }).catch(err => {
            setNimmervollUserData({})
            setLoading(false);
        });


    }, []);

    const openModal = () => {
        setModalOpen(true);
        setModalLoading(true);
        setQrCodeWidth(document.documentElement.clientWidth - (85 * 2));


        communicator('/nv/customer/card/qr', { token: getUserToken() }).then(res => {
            setQrcode(res.data);
            setModalLoading(false);
        }).catch(err => {
            setQrcode('');
            setModalOpen(false);
            setModalLoading(false);
        });

    }

    const connectCard = (data) => {

        if (data.card_number === '' || data.card_pin === '') {

            dispatch(setNotification({
                show: true,
                type: "error",
                title: 'Bitte fülle alle Felder aus.',
                closable: true,
            }))

            return false;
        }

        communicator('/nv/customer/card/attach', {
            token: getUserToken(),
            nimmervoll_customer_id: data.card_number,
            nimmervoll_customer_pin: data.card_pin
        }).then(res => {
            setNimmervollCardUsage({

            });
            console.log("RES", res)
        }).catch(err => {
            console.log("RES ERR", err)
            setNimmervollCardUsage([]);
        });
    }


    const orderCustomerCard = () => {

        console.log("orderCustomerCard")

        communicator('/nv/customer/card/order', {
            token: getUserToken(),
        }).then(res => {
            setNimmervollCardUsage({

            });
            console.log("RES", res)
        }).catch(err => {
            console.log("RES ERR", err)
            setNimmervollCardUsage([]);
        });
    }



    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto'} />
            </div><div className="col8">
                <div>
                    Hallo <strong>{nimmervollUserData.user?.first_name} {nimmervollUserData.user?.last_name},</strong>
                    <br />
                    <br />
                    willkommen in Ihrem Kundenkonto!<br />
                    Hier können <Link to="/konto/orders">Bestellungen</Link>, <Link to="/konto/addresses">Adressen</Link> und Ihre <Link to="/konto/settings">Kontoeinstellungen</Link> direkt verwaltet werden.

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    {nimmervollUserData.user?.id !== undefined ?
                        <>
                            <h4>Übersicht Vorteilskarte</h4>

                            <div className="container fluid" style={{ padding: 0 }}>
                                <div className="col6">
                                    <Card
                                        label="Mein Guthaben"
                                        value={`€ ${floatToString(parseFloat(nimmervollUserData.card?.current_credit))}`}
                                    />
                                </div>
                                <div className="col6">
                                    <Card
                                        label="Bonusstufe"
                                        value={`${floatToString(parseFloat(nimmervollUserData.card?.bonus_percent))} %`}
                                    />
                                </div>
                            </div>
                            <br />
                            <Link to="/konto/card-transitions">Ihre Vorteilskartenbewegungen</Link>
                            <br />
                            <br />
                            <br />
                            <Modal
                                state={modalOpen}
                                close={() => { setModalOpen(false); console.log("CLOSE"); }}
                                contentStyle={{
                                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                                    marginTop: 50
                                }}
                            >
                                <div className={modalStyles.header}>
                                    <p>Nimmervoll Kundenkarte</p>
                                    <h3 style={{ color: 'white' }}>{nimmervollUserData.user?.first_name} {nimmervollUserData.user?.last_name}</h3>
                                    <br />
                                    <strong>#{nimmervollUserData.user?.id}</strong>
                                </div>
                                <div className={modalStyles.body} ref={ref}>
                                    {
                                        modalLoading ?
                                            <LoadingSpinner active={true} type="wholeContainerTransparent" />
                                            :
                                            <QRCode
                                                title={''}
                                                size={qrCodeWidth}
                                                value={qrcode}
                                            />
                                    }

                                </div>
                            </Modal>
                            <br />
                            <div
                                className={modalStyles.button}
                                onClick={() => openModal()}
                            >
                                <div className={modalStyles.text}>Ihre Kundenkarte</div>
                                <div className={modalStyles.arrow}></div>
                            </div>
                            <br />
                            <br />

                        </>
                        :

                        <><form onSubmit={handleSubmit(connectCard)}>
                            <h4>Vorteilskarte hinzufügen</h4>
                            <TextInput
                                {...register('card_number')}
                                type="text"
                                required
                                label="Kundenkartennummer"
                                style={{
                                    maxWidth: 300
                                }}
                                description="Falls Sie bereits eine Kundenkarte von uns besitzen, können Sie Ihr Online-Konto mit dieser Verknüpfen und Sie können Ihren Rabatt auch online einlösen."
                            />
                            <TextInput
                                {...register('card_pin')}
                                type="text"
                                required
                                label="PIN"
                                style={{
                                    maxWidth: 300
                                }}
                                description=""
                            />
                            <Button
                                type="submit"
                                title="Verknüpfen"
                            />


                        </form>


                            <br />
                            <br />
                            <br />
                            <h4>ODER</h4>
                            <br />
                            <Button type="primary" title="Neue Kundenkarte anfodern" action={orderCustomerCard} />

                        </>

                    }
                </div>
            </div>
                    <LoadingSpinner type="page" active={loading} />

        </div>
    )
}

export default Overview

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../components/elements/Button"
import Card from "../../../components/elements/Card"
import TextInput from "../../../components/elements/Inputs/TextInput"
import { getUserData, getUserToken, setUserData } from "../../../services/auth"
import { API_BASE } from "../../../spectory-config"
import AccountNavigation from "../Navigation"
import axios from "axios";
import LoadingSpinner from "../../../components/elements/LoadingSpinner"
import { setNotification } from "../../../store/actions"
import { useDispatch } from "react-redux"

const Settings = (props) => {

    const { register, handleSubmit, reset } = useForm();

    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        var config = {
            method: 'post',
            url: `${API_BASE}/customer/getdata`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken()
            }
        };


        axios(config)
            .then(function (response) {
                console.log("response", response.data.data);

                setSettings(response.data.data);

                setLoading(false);
            })
            .catch(function (error) {
                console.log("response err", error)
                setLoading(false);
            });
    }, [])


    const send = (data) => {

        let formData = {
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name
        };
        setLoading(true)

        if (data.old !== undefined && data.new !== undefined && data.repeat !== undefined && data.old !== '' && data.new !== '' && data.repeat !== '') {

            const password = {
                old: data.old,
                new: data.new,
                repeat: data.repeat,
            };

            if (password.new != password.repeat) {

                dispatch(setNotification({
                    type: "error",
                    title: "Passwörter stimmen nicht überein",
                    show: true,
                }));

                return false;

            }

            formData.password = password


        }

        if (formData.first_name === undefined) {
            formData.first_name = settings.first_name;
        }
        if (formData.last_name === undefined) {
            formData.last_name = settings.last_name;
        }
        if (formData.email === undefined) {
            formData.email = settings.email;
        }

        var config = {
            method: 'post',
            url: `${API_BASE}/customer/setdata`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken(),
                ...formData
            }
        };

        axios(config)
            .then(function (response) {
                console.log("response", response);

                if (response.data.c === 200) {
                    dispatch(setNotification({
                        type: "success",
                        title: "Änderungen wurden gespeichert.",
                        show: true,
                    }));
                } else {
                    dispatch(setNotification({
                        type: "error",
                        title: response.data.message,
                        show: true,
                    }));
                }

                setUserData({
                    email: data.email,
                    first_name: data.first_name,
                    last_name: data.last_name,
                });

                setLoading(false);
            })
            .catch(function (error) {
                console.log("response err", error)
                setLoading(false);
            });
    }
    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto/settings'} />
            </div><div className="col8">
                <div>
                    <h4>Kontoeinstellungen</h4>
                    <br />
                    <br />
                    <form onSubmit={handleSubmit(send)}>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={settings['first_name']}
                                        type="text"
                                        label="Vorname"
                                        {...register('first_name')}
                                    />
                                </div>
                            </div>
                            <div className="col6">
                                <div>
                                    <TextInput
                                        required
                                        initValue={settings['last_name']}
                                        type="text"
                                        label="Nachname"
                                        {...register('last_name')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0, marginBottom: 50 }}>
                            <div className="col12">
                                <div>
                                    Dieser Name wird im Account und den Bewertung angezeigt.
                                </div>
                            </div>
                        </div>
                        <div className="container fluid" style={{ padding: 0 }}>
                            <div className="col12">
                                <div>
                                    <TextInput
                                        required
                                        initValue={settings['email']}
                                        type="email"
                                        label="E-Mail"
                                        {...register('email')}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="container fluid" style={{ padding: 0, marginTop: 50 }}>
                            <div className="col6">
                                <div>
                                    <h5>Passwort ändern</h5>
                                    <TextInput
                                        type="password"
                                        label="Aktuelles Passwort"
                                        {...register('old')}
                                    />
                                    <TextInput
                                        type="password"
                                        label="Neues Passwort"
                                        {...register('new')}
                                    />
                                    <TextInput
                                        type="password"
                                        label="Passwort wiederholen"
                                        {...register('repeat')}
                                    />
                                    <br />
                                    <Button
                                        title="Änderungen speichern"
                                        type="submit"
                                        additionalStyle={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            <div className="col6">
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <LoadingSpinner type="page" active={loading} />
        </div>
    )
}

export default Settings

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../components/elements/Button"
import Card from "../../../../components/elements/Card"
import TextInput from "../../../../components/elements/Inputs/TextInput"
import AccountNavigation from "../../Navigation"
import axios from "axios";
import { getUserToken } from "../../../../services/auth"
import LoadingSpinner from "../../../../components/elements/LoadingSpinner"
import { API_BASE } from "../../../../spectory-config"
import { renderDate } from "../../../../functions/date"
import { floatToString } from "../../../../functions/money"

const Orders = (props) => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        var config = {
            method: 'post',
            url: `${API_BASE}/customer/orders`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                token: getUserToken()
            }
        };

        axios(config)
            .then(function (response) {
                console.log(response)

                setOrders(response.data)

                setLoading(false);
            })
            .catch(function (error) {
                setLoading(false);
            });

    }, [])


    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto/orders'} />
            </div><div className="col8">
                <div>
                    <h4>Bestellungen</h4>

                    {
                        loading ? <LoadingSpinner active={loading} type="container" /> :
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <td>
                                        <strong>Bestellnr.</strong>
                                    </td>
                                    <td>
                                        <strong>Datum</strong>
                                    </td>
                                    <td>
                                        <strong>Status</strong>
                                    </td>
                                    <td>
                                        <strong>Summe</strong>
                                    </td>
                                    <td></td>
                                </tr>

                                {
                                    orders.map((order, key) => {
                                        return <tr key={key}>
                                            <td>
                                                {order.id}
                                            </td>
                                            <td>
                                                {renderDate(order.date.date)}
                                            </td>
                                            <td>
                                                {order.status}
                                            </td>
                                            <td>
                                                &euro; {floatToString(parseFloat(order.total))}
                                            </td>
                                            <td>
                                                <Button
                                                    type="secondary"
                                                    title="ansehen"
                                                    destination={`/konto/orders/${order.id}`}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }

                            </table>
                    }
                </div>
            </div>

        </div>
    )
}

export default Orders

import React, { useState } from "react";
import { navigate } from "gatsby";
import isLoggedIn from "../services/auth";
import LoadingSpinner from "../components/elements/LoadingSpinner";

const PrivateRoute = ({ component: Component, location, children, checkLogin, ...rest }) => {

    const [loggedIn, setIsLoggedIn] = useState(checkLogin === false);

    if (checkLogin === undefined || checkLogin === true) {
        isLoggedIn().then(loggedIn => {

            setIsLoggedIn(loggedIn);
            if (!loggedIn) {
                navigate('/login');
            }
        })
    }else{
        return <Component {...rest} />
    }


    if (loggedIn === true) {
        return <Component {...rest} />
    }

    return <LoadingSpinner active={true} type="page" />;

}

export default PrivateRoute

import React, { useEffect } from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

import PrivateRoute from "../../functions/privateRoute"
import { Router, Route } from "@reach/router"
import Overview from "./pages/_overview"
import Layout from "../../components/layout"
import Orders from "./pages/orders"
import Order from "./pages/orders/single"
import Addresses from "./pages/addresses"
import Address from "./pages/addresses/single"
import Settings from "./pages/settings"
import PaymentMethods from "./pages/paymentMethods"
import Notification from "../../components/elements/Notification"
import CardTransactions from "./pages/cardTransitions"

const Account = (props) => {

    console.log("PROPS", props)

    return (
        <Layout>
            <Router basepath={"/konto"}>
                <PrivateRoute path={"/orders"} component={Orders} />
                <PrivateRoute path={"/orders/:orderId"} component={Order} />
                <PrivateRoute path={"/addresses"} component={Addresses} />
                <PrivateRoute path={"/addresses/billing"} component={(props) => (
                    <Address {...props} type="billing" />
                )} />
                <PrivateRoute path={"/addresses/shipping"} component={(props) => (
                    <Address {...props} type="shipping" />
                )} />
                <PrivateRoute path={"/payments"} component={PaymentMethods} />
                <PrivateRoute path={"/settings"} component={Settings} />
                <PrivateRoute path={"/card-transitions"} component={CardTransactions} />
                <PrivateRoute path={"/"} component={Overview} />
            </Router>
        </Layout>
    )
}

export default Account;

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Button from "../../../../components/elements/Button"
import Card from "../../../../components/elements/Card"
import TextInput from "../../../../components/elements/Inputs/TextInput"
import AccountNavigation from "../../Navigation"
import axios from "axios";
import { getUserToken } from "../../../../services/auth"
import LoadingSpinner from "../../../../components/elements/LoadingSpinner"
import { API_BASE } from "../../../../spectory-config"
import { renderDate } from "../../../../functions/date"
import { communicator } from "../../../../services/communicator"
import { floatToString } from "../../../../functions/money"

const CardTransactions = (props) => {

    const [cardUsage, setCardUsage] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        communicator('/nv/customer/card/transactions', { token: getUserToken() }).then(res => {
            console.log("RES", res.data)
            setCardUsage(res.data);
            setLoading(false);
        }).catch(err => {
            setCardUsage([])
            setLoading(false);
        });


    }, [])


    return (
        <div className="container" style={{ paddingTop: 100, paddingBottom: 100 }}>
            <div className="col4">
                <AccountNavigation current={'konto'} />
            </div><div className="col8">
                <div>
                    <h4>VORTEILSKARTENBEWEGUNG</h4>

                    {
                        loading ? <LoadingSpinner active={loading} type="container" /> :
                            <>
                                <table style={{ width: '100%' }} cellPadding={10}>
                                    <tr>
                                        <td>
                                            <strong>Bestellnr.</strong>
                                        </td>
                                        <td>
                                            <strong>Datum</strong>
                                        </td>
                                        <td>
                                            <strong>Summe</strong>
                                        </td>
                                        <td>
                                            <strong>Bonus %</strong>
                                        </td>
                                        <td>
                                            <strong>Bonus</strong>
                                        </td>
                                    </tr>

                                    {
                                        cardUsage.length > 0 && cardUsage.map((usage, key) => {
                                            return <tr key={key}>
                                                <td>
                                                    {usage.receipt}
                                                </td>
                                                <td>
                                                    {renderDate(usage.date)}
                                                </td>
                                                <td>
                                                    &euro; {floatToString(parseFloat(usage.revenue))}
                                                </td>
                                                <td>
                                                    {floatToString(parseFloat(usage.bonus_percent))}
                                                </td>
                                                <td>
                                                    &euro; {floatToString(parseFloat(usage.bonus))}
                                                </td>
                                            </tr>
                                        })
                                    }

                                </table>

                                <Button
                                    additionalStyle={{ marginTop: 100 }}
                                    type="secondary"
                                    title="Zurück zum Kundenbereich"
                                    destination="/konto"
                                />
                            </>
                    }
                </div>
            </div>

        </div>
    )
}

export default CardTransactions
